import React from "react"
import Helmet from "react-helmet"

const SoftwareApplicationJsonLD = () => {
  const organizationldJson = {
    "@context": "http://schema.org",
    "@type": "Organization",
    name: "Dynobase",
    logo: "https://vtcsz2c-r4lha2oo.s3.amazonaws.com/static/dynobase_banner.png",
    url: "https://dynobase.dev/",
    sameAs: [
      "https://twitter.com/dynobase",
      "https://www.linkedin.com/company/dynobase",
    ],
  }

  const ldJson = {
    "@context": "http://schema.org",
    "@type": "SoftwareApplication",
    image:
      "https://vtcsz2c-r4lha2oo.s3.amazonaws.com/static/dynobase_banner.png",
    name: "Dynobase",
    author: {
      "@type": "Organization",
      url: "https://dynobase.dev/",
      name: "Dynobase",
    },
    aggregateRating: {
      "@type": "AggregateRating",
      ratingValue: "4.9",
      ratingCount: "864",
    },
    datePublished: "2018-12-01",
    fileSize: "150mb",
    description: "Professional GUI Client for DynamoDB",
    downloadURL: "https://dynobase.dev/download",
    operatingSystem: ["Mac OS", "Windows", "Linux"],
    applicationCategory: "DeveloperApplication",
    Offers: {
      "@type": "Offer",
      price: "0.00",
      priceCurrency: "USD",
    },
  }

  const ldJsonString = JSON.stringify(ldJson, null, 2)
  const organizationldJsonString = JSON.stringify(organizationldJson, null, 2)

  return (
    <>
      <Helmet>
        <script type="application/ld+json">{organizationldJsonString}</script>
        <script type="application/ld+json">{ldJsonString}</script>
      </Helmet>
    </>
  )
}

export default SoftwareApplicationJsonLD
