import React from "react"
import { Text } from "rebass"

const PricingHeadline = props => (
  <Text
    sx={{
      marginBottom: [0],
      marginTop: [62, 62, 82],
    }}
    style={{
      textAlign: "center",
      fontSize: "2rem",
      fontWeight: "600",
      lineHeight: "1.1",
    }}
    id="pricing"
  >
    Get Started Now
  </Text>
)

export default PricingHeadline
