import React, { useEffect } from 'react'
import { Box } from "rebass"
import Container from "@components/container"
import getUserCountry from "../../../utils/getUserCountryCode";

const AdditionalTaxes = () => {
  const [ visitorFromUSA, setVisitorFromUSA ] = React.useState(true);

  function handleVisitorCountryCode(visitorCountryCode) {
    if(visitorCountryCode !== "US") {
      setVisitorFromUSA(false);
    }
  }

  useEffect(() => {
      async function USAVisitorCheck() {
          const localStorageKey = "pp";
          const visitorCountryCode = localStorage.getItem(localStorageKey);
          if(visitorCountryCode) {
            handleVisitorCountryCode(visitorCountryCode);
          } else {
            const visitorCountryCode = await getUserCountry();
            handleVisitorCountryCode(visitorCountryCode);
          }
      }
      USAVisitorCheck();
  }, [])
  return (
    <>
    {
      !visitorFromUSA && 
      <Container maxWidth="980px">
        <Box width={"100%"} p={4} backgroundColor={"#f8f8f8"} mt={4} mb={[4, 0]}>
          Please note: depending on your country's tax rules, additional VAT/GST may be added to the final amount.
        </Box>
      </Container>
    }
    </>
  )
}

export default AdditionalTaxes;