import React from "react"
import { Box, Flex } from "rebass"

const BigFeature = ({
  title,
  description,
  ltr,
  background,
  images,
  mobileImage,
  learnMoreLink,
}) => {
  return (
    <section
      style={{
        background,
        padding: "100px",
        margin: ltr ? "50px -100px" : "50px -100px 100px -100px",
        overflow: "hidden",
      }}
      className="mobile-friendly-big-feature"
    >
      <Flex
        flexDirection={[
          "column",
          ltr ? "row" : "row-reverse",
          ltr ? "row" : "row-reverse",
        ]}
        style={{
          textAlign: "left",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Box
          width={[1, "40%", "40%"]}
          marginLeft={[0, !ltr ? "60px" : "auto", !ltr ? "60px" : "auto"]}
          style={{
            textAlign: "left",
          }}
        >
          <h2
            style={{
              color: "#222",
              textAlign: "left",
              fontWeight: 700,
              fontSize: "2.5rem",
              marginTop: "20px",
              marginBottom: "0.75rem",
            }}
          >
            {title}
          </h2>
          <p
            style={{
              color: "#222",
              fontWeight: 400,
              fontSize: "1rem",
              textAlign: "left",
              marginBottom: "30px",
            }}
          >
            {description}
          </p>
          {learnMoreLink && (
            <a
              style={{
                fontSize: "14px",
                marginTop: "-16px",
              }}
              href={learnMoreLink}
            >
              Learn More
            </a>
          )}
        </Box>
        <Box
          display={["none", "block", "block"]}
          style={{
            height: "400px",
            width: "calc(60% + 100px)",
            marginRight: ltr ? "-100px" : 0,
            marginLeft: !ltr ? "-100px" : 0,
          }}
        >
          {images}
        </Box>
        <Box
          display={["block", "none", "none"]}
          style={{
            height: "300px",
            width: "100%",
            margin: "10px",
          }}
        >
          {mobileImage}
        </Box>
      </Flex>
    </section>
  )
}

export default BigFeature
