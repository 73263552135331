import React from 'react';
import { support } from "@constants/urls.js"
import { InternalLink } from "@fragments"

const MoreQuestions = ({customHeadline}) => (
    <div>
        <h3 style={{ textAlign: "center", marginTop: 100, marginBottom: 5 }}>
            {customHeadline || "Have another question?"}
        </h3>
        <p id="contact" style={{ textAlign: "center" }}>
            We're here to help. Use{" "}
            <InternalLink to={support}>live chat</InternalLink> or email{" "}
            <a href="mailto:support@dynobase.dev">support@dynobase.dev</a>
        </p>
    </div>    
);

export default MoreQuestions;