import React from "react"
import { Box } from "rebass"
import BigFeature from "./BigFeature"
import Tesimonial from "./Testimonials/Tesimonial"
import { StaticImage } from "gatsby-plugin-image"
import UsedByCompanies from "./UsedByCompanies"

const BigFeatures = ({ data }) => {
  return (
    <div style={{ marginTop: "100px" }}>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <UsedByCompanies data={data} />
        {/* <Tesimonial
          author="Alex DeBrie, AWS Data Hero"
          authorSub="Author of DynamoDBBook.com and DynamoDBGuide.com"
          authorLink="https://twitter.com/alexdebrie"
          imageName="alex.jpg"
          allImageSharpNodes={data.allImageSharp.nodes}
        >
          <p style={{ marginBottom: 0 }}>
            <b>I love using Dynobase</b> to explore my tables with a native app
            rather than going to the AWS console in my browser.
          </p>
        </Tesimonial> */}
      </div>
      <div
        style={{
          textAlign: "center",
          marginTop: "32px",
          fontSize: "2rem",
          fontWeight: "600",
          lineHeight: "1.1",
          letterSpacing: -1,
          marginBottom: "40px",
          padding: "0 40px",
          width: "100%",
        }}
        id="features"
      >
        10x Your DynamoDB Productivity
      </div>
      <BigFeature
        ltr={true}
        background="#f5f5f5"
        title="Jump between profiles and regions. Fast."
        description={
          <>
            <span>
              No more browser extensions, multiple windows, constant re-logging
              between roles and accounts.
            </span>
            <br />
            <br />
            <span>
              Use the same profiles as in CLI or SDK. Works with both short and
              long-lived credentials, MFA-protected profiles, AWS SSO-based
              identities and tools like aws-vault.
            </span>
          </>
        }
        mobileImage={
          <StaticImage
            src={"../../../images/basic-view.png"}
            alt="Editing DynamoDB items inline"
            quality={100}
            height={300}
            objectPosition={"left"}
            loading="lazy"
            objectFit={"cover"}
            style={{
              boxShadow: "0",
            }}
          />
        }
        images={
          <Box display={["none", "block", "block"]}>
            <div
              style={{
                position: "relative",
                width: "100%",
                height: "100%",
              }}
            >
              <StaticImage
                src={"../../../images/aws-profiles.png"}
                alt="Section image"
                height={400}
                objectPosition={"left"}
                loading="lazy"
                objectFit={"fill"}
                style={{
                  position: "absolute",
                  boxShadow: "0",
                  top: 30,
                  left: -120,
                  marginRight: "-250px",
                  marginLeft: "100px",
                  isolation: "isolate",
                }}
              />
              <StaticImage
                src={"../../../images/basic-view.png"}
                alt="Section image"
                height={1000}
                objectPosition={"left"}
                quality={100}
                objectFit={"fill"}
                loading="lazy"
                style={{
                  position: "absolute",
                  top: -40,
                  left: 0,
                  boxShadow: "0",
                  marginRight: "-350px",
                  marginLeft: "140px",
                  isolation: "isolate",
                }}
              />
            </div>
          </Box>
        }
      />
      <BigFeature
        ltr={false}
        title="Edit data with ease."
        description={`Modify DynamoDB items just like you would modify JSON.`}
        learnMoreLink="/dynamodb-update-item/"
        mobileImage={
          <StaticImage
            src={"../../../images/inline-editor.png"}
            alt="Editing DynamoDB items inline"
            quality={70}
            height={300}
            objectPosition={"left"}
            loading="lazy"
            objectFit={"cover"}
            style={{
              boxShadow: "0",
            }}
          />
        }
        images={
          <div>
            <StaticImage
              src={"../../../images/inline-editor.png"}
              alt="Editing DynamoDB items inline"
              quality={100}
              height={1000}
              objectPosition={"left"}
              loading="lazy"
              objectFit={"cover"}
              style={{
                marginTop: "-40px",
                boxShadow: "0",
                marginLeft: "-100px",
              }}
            />
            <StaticImage
              src={"../../../images/json-editor.png"}
              alt="Editing DynamoDB items using Dynobase with JSON editor"
              quality={100}
              height={1000}
              objectPosition={"left"}
              loading="lazy"
              objectFit={"cover"}
              style={{
                marginTop: "-205px",
                boxShadow: "0",
                marginLeft: "100px",
                boxShadow: "rgba(0, 0, 0, 0.15) 3px 4px 10px 4px",
              }}
            />
          </div>
        }
      />
      <BigFeature
        ltr={true}
        background="#f5f5f5"
        title="Create queries and find data easily."
        description={
          <>
            <span>
              Which index was that? What should this query look like? Was it
              Scan or Query?
            </span>
            <br />
            <br />

            <span>
              It doesn't matter - Dynobase figures that out for you. It even
              works with SQL.
            </span>
            <br />
            <br />
            <span>
              Plus, all your queries can be exported to code snippets, ready to
              be used in your project.
            </span>
          </>
        }
        learnMoreLink="/dynamodb-query/"
        mobileImage={
          <StaticImage
            src={"../../../images/query-in-action.png"}
            alt="Querying DynamoDB using Dynobase"
            quality={70}
            height={300}
            objectPosition={"left"}
            loading="lazy"
            objectFit={"cover"}
            style={{
              boxShadow: "0",
            }}
          />
        }
        images={
          <StaticImage
            src={"../../../images/query-in-action.png"}
            alt="Querying DynamoDB using Dynobase"
            height={1000}
            objectPosition={"left"}
            quality={100}
            loading="lazy"
            objectFit={"fill"}
            style={{
              marginTop: "-40px",
              boxShadow: "0",
              marginRight: "-150px",
            }}
          />
        }
      />
      <BigFeature
        ltr={false}
        title="✨ Dynobase AI"
        // learnMoreLink="/dynobase-ai/"
        description={
          <>
            <span>
              Use the help of AI to generate DynamoDB code snippets for you.
              These snippets are available in JS/TS, Rust, Golang, and Python.
            </span>
            <br />
            <br />
            <span>
              Simply explain your needs in natural language, and receive
              production-ready code in return.
            </span>
          </>
        }
        mobileImage={
          <StaticImage
            src={"../../../images/dynobase-ai.png"}
            alt="Generating DynamoDB Code using AI"
            quality={70}
            height={300}
            objectPosition={"left"}
            loading="lazy"
            objectFit={"cover"}
            style={{
              boxShadow: "0",
            }}
          />
        }
        images={
          <div>
            <StaticImage
              src={"../../../images/dynobase-ai.png"}
              alt="Generating DynamoDB Code using AI"
              loading="lazy"
              quality={100}
              height={1000}
              objectPosition={"left"}
              objectFit={"cover"}
              style={{
                marginTop: "-40px",
                boxShadow: "0",
                marginLeft: "-220px",
              }}
            />
          </div>
        }
      />
      <BigFeature
        ltr={true}
        background="#f5f5f5"
        title="Import & Export"
        learnMoreLink="/import-csv-to-dynamodb/"
        description={
          <>
            <span>
              Doesn't matter whether you need to export or import data, if it's
              CSV or JSON. Dynobase can do it for you. Types conversion
              included.
            </span>
            <br />
            <br />
            <span>
              Get rid of complex scripts. Migrate to DynamoDB in a few clicks.
            </span>
          </>
        }
        mobileImage={
          <StaticImage
            src={"../../../images/import-full.png"}
            alt="Querying DynamoDB using Dynobase"
            quality={70}
            height={300}
            objectPosition={"left"}
            loading="lazy"
            objectFit={"cover"}
            style={{
              boxShadow: "0",
            }}
          />
        }
        images={
          <div>
            <StaticImage
              src={"../../../images/import-full.png"}
              alt="DynamoDB Importing data screen from CSV or JSON"
              loading="lazy"
              quality={100}
              height={1000}
              objectPosition={"left"}
              objectFit={"cover"}
              style={{
                marginTop: "0px",
                boxShadow: "0",
                marginLeft: "-10px",
                marginRight: "0px",
              }}
            />
          </div>
        }
      />
    </div>
  )
}

export default BigFeatures
