import React from "react"
import Section from "@components/section"

const UnleashThePower = ({ data }) => (
  <React.Fragment>
    <div
      style={{
        textAlign: "center",
        marginTop: "122px",
        fontSize: "2rem",
        fontWeight: "600",
        lineHeight: "1.1",
        letterSpacing: -1,
        marginBottom: "40px",
        padding: "0 40px",
        width: "100%",
      }}
      className="unleash-the-power-headline"
    >
      Unleash the full power of Amazon DynamoDB
    </div>
    <Section
      title="Integrate With Your Workflow"
      desc="Dynobase allows exporting of all operations into CLI and popular languages SDK formats. No more writing queries; just copy and paste straight into your favorite IDE."
      imageName="params.png"
      allImageSharpNodes={data.allImageSharp.nodes}
    />
    <Section
      title="Terminal"
      desc="Thanks to Terminal, you can blur the line between UI and Code. Slice and dice your data inside Dynobase to get results filtered and transformed beyond normal capabilities using Javascript."
      imageName="terminal.png"
      allImageSharpNodes={data.allImageSharp.nodes}
      background="#f5f5f5"
    />
    <Section
      title="Bookmarks and History"
      desc="Save and load frequently used queries and scans. Use the history of actions just like in your web browser."
      imageName="history.png"
      allImageSharpNodes={data.allImageSharp.nodes}
    />
    <Section
      title="Operation Builder"
      desc="Creating DynamoDB operations, such as updating & deleting items or transactions, can be overwhelming. WYSIWYG Operation Builder will generate a production-grade code for you."
      imageName="operation-builder.png"
      allImageSharpNodes={data.allImageSharp.nodes}
      background="#f5f5f5"
    />
  </React.Fragment>
)

export default UnleashThePower
