import "@fontsource/inter"
import React from "react"
import Layout from "@components/layout"
import SEO from "@components/seo"
import Footer from "@components/footer/index"
import Pricing from "@components/pages/home/Pricing/index"
import EvenMoreFeatures from "@components/pages/home/EvenMoreFeatures"
import UnleashThePower from "@components/pages/home/UnleashThePower"
import Testimonials from "@components/pages/home/Testimonials"
import Tesimonial from "@components/pages/home/Testimonials/Tesimonial"
import Hero from "@components/pages/home/Hero"
import Container from "@components/container"
import { graphql } from "gatsby"
import BigFeatures from "@components/pages/home/BigFeatures"
import SoftwareApplicationJsonLD from "@components/SoftwareApplicationJsonLD"
import ReadOnlyDesigner from "../components/pages/home/ReadOnlySingleTableDesigner"

const IndexPage = ({ data }) => {
  return (
    <>
      <div
        style={{
          position: "absolute",
          backgroundColor: "rgb(24, 144, 255)",
          overflow: "hidden",
          width: "100%",
          height: "850px",
          msTransform: "skewY(-6deg)",
          transform: "skewY(-6deg)",
          WebkitTransform: "skewY(-6deg)",
          top: -300,
          zIndex: -1000,
        }}
      >
        {" "}
      </div>
      <div
        style={{
          position: "absolute",
          backgroundColor: "rgb(24, 144, 255, 0.3)",
          overflow: "hidden",
          width: "100%",
          height: "860px",
          msTransform: "skewY(-6deg)",
          transform: "skewY(-6.6deg)",
          WebkitTransform: "skewY(-6.2deg)",
          top: -280,
          zIndex: -1000,
        }}
      >
        {" "}
      </div>
      <Layout showParityOffer hidePreheaderCTA>
        <SEO
          title="Dynobase - Professional DynamoDB GUI Client"
          description="Modern DynamoDB IDE Client. Accelerate DynamoDB workflow with sleek Admin UI, visual query builder, codegen and more! Available for Mac, Windows &amp; Linux."
          canonical="https://dynobase.dev/"
        />
        <Container maxWidth={1040}>
          <Hero data={data} />
          <SoftwareApplicationJsonLD />

          <BigFeatures data={data} />
          <UnleashThePower data={data} />
          <ReadOnlyDesigner />
          <div style={{ margin: "0px -20px" }}>
            <EvenMoreFeatures />
          </div>
          <Testimonials data={data} />
          <Pricing data={data} />
        </Container>
        <Footer />
      </Layout>
    </>
  )
}

export const query = graphql`
  query {
    allImageSharp {
      nodes {
        gatsbyImageData(width: 800)
        fluid {
          originalName
        }
      }
    }
  }
`

export default IndexPage
