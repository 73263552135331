import React, { useState } from "react"
import { Box } from "rebass"
import MobileDownloadCTA from "@components/MobileDownloadCTA"
import DesktopDownloadForm from "@components/mailchimpFormDesktopDownload.js"
import { getImageNode } from "../../../utils/getImage"
import { StaticImage } from "gatsby-plugin-image"

const Hero = props => {
  const [isVideo, setVideo] = useState(false)

  return (
    <React.Fragment>
      <h1
        style={{
          color: "white",
          fontWeight: 600,
          fontSize: "2.5rem",
          textAlign: "center",
          marginTop: "62px",
          marginBottom: "0.5rem",
        }}
      >
        Professional GUI Client for DynamoDB
      </h1>
      <p
        style={{
          color: "white",
          fontWeight: 400,
          fontSize: "1rem",
          textAlign: "center",
          marginBottom: "30px",
        }}
      >
        Accelerate your AWS DynamoDB workflow with faster data exploration, code
        generation, bookmarks, and more.
      </p>
      <MobileDownloadCTA />
      <Box display={["none", "none", "block"]} mb={3}>
        <DesktopDownloadForm data={props.data} />
      </Box>
      <p
        style={{
          textAlign: "center",
          color: "rgba(0,0,0,0.7)",
          fontSize: "0.7em",
          lineHeight: 1.3,
          marginTop: "8px",
          marginBottom: "32px",
        }}
      >
        <span style={{ marginRight: "16px", color: "white" }}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="10"
            height="10"
            viewBox="0 0 24 24"
            style={{ marginBottom: "-1px" }}
            fill="white"
          >
            <path d="M20.285 2l-11.285 11.567-5.286-5.011-3.714 3.716 9 8.728 15-15.285z" />
          </svg>{" "}
          7-day free trial
        </span>
        <span style={{ marginRight: "16px", color: "white" }}>
          {" "}
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="10"
            height="10"
            viewBox="0 0 24 24"
            style={{ marginBottom: "-1px" }}
            fill="white"
          >
            <path d="M20.285 2l-11.285 11.567-5.286-5.011-3.714 3.716 9 8.728 15-15.285z" />
          </svg>{" "}
          No credit card required
        </span>
        <span style={{ marginRight: "0", color: "white" }}>
          {" "}
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="10"
            height="10"
            viewBox="0 0 24 24"
            style={{ marginBottom: "-1px" }}
            fill="white"
          >
            <path d="M20.285 2l-11.285 11.567-5.286-5.011-3.714 3.716 9 8.728 15-15.285z" />
          </svg>{" "}
          Available for macOS{" "}
          <a
            style={{ color: "white" }}
            href="https://builds.dynobase.dev/mac/dmg/arm64"
          >
            (including Apple Silicon)
          </a>
          , Linux and Windows
        </span>
      </p>
      {!isVideo ? (
        <StaticImage
          src={"../../../images/hero.png"}
          alt="Dynobase Desktop Application Demo"
          placeholder="none"
          loading="eager"
          style={{
            margin: "-20px",
            borderRadius: 5,
          }}
        />
      ) : (
        <div
          style={{
            overflow: "hidden",
            paddingTop: "56.25%",
            position: "relative",
            marginBottom: "4.9em",
          }}
        >
          {" "}
          <iframe
            style={{
              border: "0",
              height: "100%",
              left: "0",
              position: "absolute",
              top: "0",
              width: "100%",
            }}
            width="100%"
            height="100%"
            src="https://www.youtube.com/embed/SGHBz6qe_YE"
            frameBorder="0"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            title="dynamodb gui client"
            allowFullScreen
            autoPlay="1"
          ></iframe>
        </div>
      )}
      {!isVideo && (
        <p
          style={{
            textAlign: "center",
            color: "rgba(0,0,0,0.7)",
            fontSize: "0.7em",
            cursor: "pointer",
            marginTop: "0.8em",
            marginBottom: "2.5em",
          }}
          onClick={() => setVideo(true)}
        >
          See Dynobase in action - Click to Play Video
        </p>
      )}
    </React.Fragment>
  )
}

export default Hero
