import React from "react"
import Plan from "@components/plan"
import { Box } from "rebass"

const PricingTable = () => (
  <Box
    mx={[10, 15, "-10%"]}
    width={[1, 1, "120%"]}
  >
    <Plan
      title="Solo - Yearly"
      price="$9"
      pricePerMonth
      priceTime="$108 billed annually"
      licenseDetails="1 License"
      // downloadUrl={buyProduct}
      productId={588704}
    />
    <Plan
      title="Solo - Lifetime"
      price="$199"
      priceTime="one-time payment"
      licenseDetails="1 License"
      // downloadUrl={buyProduct}
      productId={585770}
      isMostPopular
    />
    <Plan
      title="Team - Yearly"
      price="$79"
      pricePerMonth
      priceTime="billed annually"
      licenseDetails="team"
      // downloadUrl={buyProduct}
      productId={747458}
    />
    <Plan
      title="Enterprise"
      price="Custom Pricing"
      priceTime=""
      licenseDetails="Unlimited Licenses"
      customOnClick="mailto:support@dynobase.dev"
      planPriceStyles={{
        fontSize: "1.4em",
        padding: "16px 10px",
      }}
      cta="Contact Us"
    />
  </Box>
)

export default PricingTable
