import React from "react"
import { GatsbyImage } from "gatsby-plugin-image"

const UsedByCompanies = ({ data }) => (
  <div
    style={{ padding: "40px 10px 120px", maxWidth: "130%", overflow: "hidden" }}
  >
    <h3 style={{ textAlign: "center" }}>
      Trusted by engineers from successful companies
    </h3>
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-around",
        alignItems: "center",
        // flexWrap: "wrap",
        margin: "auto",
      }}
    >
      {[
        "hashicorp.png",
        "rivian.png",
        "twitch.png",
        "buildspace.png",
        "flixbus.png",
        // "trek10.png",
        // "deloitte.png",
        // "zynga.png",
      ].map(imageName => {
        const image = data.allImageSharp.nodes.find(n =>
          n.fluid.originalName.includes(imageName)
        )

        if (!image) {
          return <div>Image not found</div>
        }

        return (
          <GatsbyImage
            image={image.gatsbyImageData}
            alt={`company logo`}
            loading="lazy"
            style={{
              maxWidth: "140px",
              width: "140px",
              margin: "20px 10px",
              filter: "grayscale(100%)",
              filter: "brightness(0%)",
              opacity: "0.5",
            }}
          />
        )
      })}
    </div>
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-around",
        alignItems: "center",
        // flexWrap: "wrap",
        margin: "auto",
      }}
    >
      {[
        // "hashicorp.png",
        // "rivian.png",
        // "twitch.png",
        // "buildspace.png",
        "cloudzero.png",
        "trek10.png",
        "deloitte.png",
        "zynga.png",
      ].map(imageName => {
        const image = data.allImageSharp.nodes.find(n =>
          n.fluid.originalName.includes(imageName)
        )

        if (!image) {
          return <div>Image not found</div>
        }

        return (
          <GatsbyImage
            image={image.gatsbyImageData}
            alt={`company logo`}
            loading="lazy"
            style={{
              maxWidth: "140px",
              width: "140px",
              margin: "20px 10px",
              filter: "grayscale(100%)",
              filter: "brightness(0%)",
              opacity: "0.5",
            }}
          />
        )
      })}
    </div>
  </div>
)

export default UsedByCompanies
