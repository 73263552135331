import React from "react"
import { Box } from "rebass"

const ReadOnlyDesigner = () => (
  <Box display={["none", "block", "block"]} mt="132px">
    <div
      style={{
        background: "#f5f5f5",
        margin: "-50px -200px 100px",
        padding: "50px 200px 100px",
      }}
    >
      <h2
        style={{
          color: "#222",
          fontWeight: 600,
          fontSize: "2.5rem",
          textAlign: "center",
          marginTop: "62px",
          lineHeight: "1.2",
        }}
      >
        "Dynobase should absolutely be your go to tool for DynamoDB data
        modeling."
      </h2>
      <a
        href="https://twitter.com/houlihan_rick/status/1469808564730531841"
        target="_blank"
        rel="noreferrer noopener"
        style={{
          textDecorationColor: "white",
          textAlign: "center"
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginTop: "-15px"
          }}
        >
         <img
            alt={"Rick Houlihan"}
            src={"https://pbs.twimg.com/profile_images/1300867201713557506/32qD71b1_normal.png"}
            loading="lazy"
            style={{
              borderRadius: "50%",
              height: "32px",
              width: "32px",
              marginRight: "8px"
            }}
          />
        <h5
          style={{
            zIndex: 10000,
            color: "#555",
            textAlign: "center",
          }}
        >
          Rick Houlihan
        </h5>
        </div>
      </a>
      <h6
        style={{
          marginTop: "-20px",
          color: "#555",
          textAlign: "center",
        }}
      >
        Inventor of Single-Table Design, Director Developer Relations @ MongoDB
      </h6>

      <iframe
        style={{
          marginTop: "-80px",
          marginBottom: "-60px",
          boxShadow: "rgba(0, 0, 0, 0.15) 3px 4px 10px 4px",
          marginLeft: "-20%",
          border: "1px #e5e5e5 solid",
          backgroundColor: "white",
          borderRadius: 5,
          transform: "scale(0.75)",
        }}
        width="140%"
        loading="lazy"
        height="642px"
        src={`https://viewer.dynobase.dev/?modelUrl=https://raw.githubusercontent.com/Dynobase/single-table-models/main/Movies/Movies.json`}
      />
      <p
        style={{
          fontSize: "12px",
          textAlign: "center",
          margin: "auto",
        }}
      >
        Go ahead, play with the read-only version above.
      </p>
    </div>
  </Box>
)

export default ReadOnlyDesigner
