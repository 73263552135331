import React, { useEffect } from "react"
import { memberPortal } from "@constants/urls.js"
import { ExternalLink } from "@fragments"
import PricingTable from "./Table"
import MoreQuestions from "./MoreQuestions"
import PricingHeadline from "./Headline"
import { Box } from "rebass"
import MobileDownloadCTA from "@components/MobileDownloadCTA"
import DesktopDownloadForm from "@components/mailchimpFormDesktopDownload.js"
import AdditionalTaxes from "@components/pages/buy/AdditionalTaxes"

const Pricing = props => {
  return (
    <React.Fragment>
      <PricingHeadline />
      <AdditionalTaxes />
      <PricingTable />
      <p
        style={{
          textAlign: "center",
          color: "rgba(0,0,0,0.8)",
          fontSize: "1.2em",
          fontWeight: 700,
          marginTop: 100,
          marginBottom: 0,
        }}
      >
        Haven't tried Dynobase yet?
      </p>
      <p
        style={{
          textAlign: "center",
          color: "rgba(0,0,0,0.8)",
          fontSize: "0.8em",
          fontWeight: 400,
          marginBottom: 20,
        }}
      >
        Try it for 7 days, no strings attached.
      </p>
      <MobileDownloadCTA />
      <Box display={["none", "none", "block"]} mb={3}>
        <DesktopDownloadForm primary data={props.data} />
      </Box>
      <p
        style={{
          textAlign: "center",
          color: "rgba(0,0,0,0.7)",
          fontSize: "0.7em",
          lineHeight: 1.3,
          marginTop: "2px",
          marginBottom: "32px",
        }}
      >
        <span style={{ marginRight: "16px" }}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="10"
            height="10"
            viewBox="0 0 24 24"
            style={{ marginBottom: "-1px" }}
          >
            <path d="M20.285 2l-11.285 11.567-5.286-5.011-3.714 3.716 9 8.728 15-15.285z" />
          </svg>{" "}
          7-day free trial
        </span>
        <span style={{ marginRight: "16px" }}>
          {" "}
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="10"
            height="10"
            viewBox="0 0 24 24"
            style={{ marginBottom: "-1px" }}
          >
            <path d="M20.285 2l-11.285 11.567-5.286-5.011-3.714 3.716 9 8.728 15-15.285z" />
          </svg>{" "}
          No credit card required
        </span>
        <span style={{ marginRight: "0" }}>
          {" "}
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="10"
            height="10"
            viewBox="0 0 24 24"
            style={{ marginBottom: "-1px" }}
          >
            <path d="M20.285 2l-11.285 11.567-5.286-5.011-3.714 3.716 9 8.728 15-15.285z" />
          </svg>{" "}
          Available for macOS, Linux and Windows
        </span>
      </p>

      <h3 style={{ textAlign: "center", marginTop: 120, marginBottom: 5 }}>
        Frequently Asked Questions
      </h3>
      <Box p={["0 20px", "0 60px", "0 60px"]} style={{ marginTop: "62px" }}>
        <h4>How does Dynobase connect to my DynamoDB tables?</h4>
        <p>
          Dynobase uses profiles stored in a folder named <i>.aws</i> in your
          home directory. It’s the same method that other various CLI and SDK
          tools are using. Dynobase is not modifying this file, neither is it
          storing them in any other place or sending them to 3rd party APIs.
          Dynobase also supports <a href="/dynobase-and-aws-sso/">AWS SSO</a>{" "}
          and external credential providers like{" "}
          <a href="/dynobase-and-aws-vault/"> aws-vault</a>. This way, your AWS
          account, and identity are kept secure.
        </p>
        <h4>What is your cancellation policy?</h4>
        <p>
          If you no longer wish to use Dynobase, you may cancel at any time, and
          we will not bill you again. To do that, log in to your account at{" "}
          <ExternalLink to={memberPortal}>member.dynobase.dev</ExternalLink> and
          select <i>Cancel</i>.
        </p>
        <h4>How many devices can I activate with purchase or subscription?</h4>
        <p>
          You can register up to 3 devices per subscription or purchase. You can
          deactivate old devices that you’re not using anymore.
        </p>
        <h4>
          Can I use Dynobase to connect with DynamoDB Offline or LocalStack?
        </h4>
        <p>
          Yes, Dynobase works perfectly with DynamoDB Offline distributions,
          including the ones using Docker. Click here to{" "}
          <ExternalLink to="/dynamodb-local-admin-gui/">
            learn more about offline support
          </ExternalLink>
          .
        </p>
      </Box>
      <div>
        <MoreQuestions />
      </div>
    </React.Fragment>
  )
}

export default Pricing
